

export const emailValidation = (email: string): (null | string) => {
    if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            email
        )
    ) {
        return null;
    }
    if (email.trim() === "") {
        return "Email is required";
    }
    return "Please enter a valid email";
};

export const stringValidation = (fieldName: string, fieldValue: string, minLength?: number): (null | string) => {
    if (fieldValue.trim() === "") {
        return `${fieldName} es requerido`;
    }
    if (/[^a-zA-Z -]/.test(fieldValue)) {
        return "caracteres invalidos";
    }
    if (minLength && fieldValue.trim().length < minLength) {
        return `${fieldName} debe contener al menos ${minLength} caracteres`;
    }
    return null;
};